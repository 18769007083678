import React from 'react'
import '../components/board.css'
import Layout from '../components/layout'
import pnf from '../images/404.png'

const BoardMember = () => {
  return (
    <Layout>
      <div className="team-player">
        <div className="box center">
          <div className="box center">
            <img src={pnf} style={{ width: '25%', height: '25%' }} />{' '}
            <h1> Page not found </h1>
          </div>{' '}
        </div>{' '}
      </div>{' '}
    </Layout>
  )
}

export default BoardMember
